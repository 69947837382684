import { DatabaseSchema } from 'common/databaseSchema'
import { PhoneRegistration } from 'common/types'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Centered } from 'shared/components/Centered'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { useRefreshInterval } from 'shared/hooks/useRefreshInterval'
import { isObjectEmpty } from 'shared/utils/defined'
import { ms, ms2sec } from 'shared/utils/time'
import { siteUrl } from 'shared/utils/url'
import { cn } from 'shared/utils/web/cn'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { Hint, Text, Title } from '../../components/Text'
import { Button } from '../../components/ui/button'
import { remove, update } from '../../firebaseMethods'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'
import { AddDeviceDialog } from './AddDeviceDialog'
import { AddPhoneDialog } from './AddPhoneDialog'

type RGB = [number, number, number]

const getRgbsFromColorId = (colorId: number): [RGB, RGB] => {
  const colorIdNumber = colorId

  const leftColorId = Math.floor(colorIdNumber / 7)
  const rightColorId = (colorIdNumber % 7) + 1

  function asRGB(colorId: number) {
    return [colorId & 4, colorId & 2, colorId & 1].map((value) =>
      value ? 255 : 0,
    ) as RGB
  }

  const leftRGB = asRGB(leftColorId)
  const rightRGB = asRGB(rightColorId)

  return [leftRGB, rightRGB]
}

const LED: React.FC<{ rgb: RGB; blink?: boolean }> = ({ rgb, blink }) => {
  const [red, green, blue] = rgb
  const isOff = red + green + blue === 0
  const color = `rgb(${red}, ${green}, ${blue})`
  return (
    <div
      className={cn(
        { 'animate-pulse': blink, hidden: isOff },
        'h-4 w-4 rounded-full shadow-sm',
      )}
      style={{ backgroundColor: color }}
    />
  )
}

// Defined outside of component to get a constant reference
const refPathsMap = {
  pendingDevices: 'pendingDevices' as const,
  pendingPhone: 'pendingPhone' as const,
  recentDevices: 'recentDevices_' as const,
}

const REFRESH_INTERVAL = ms(1, 'second')
const MAX_LAST_PING_DELAY = ms(5, 'seconds')

export const Pending: React.FC = () => {
  useRefreshInterval(REFRESH_INTERVAL)

  const pendingState =
    useMergedFirebase<MergedType<typeof refPathsMap, DatabaseSchema>>(
      refPathsMap,
    )

  const [addDeviceDialogData, setAddDeviceDialogData] = useState<{
    deferred: Deferred<void>
    serial: string
  } | null>(null)

  const [addPhoneDialogData, setAddPhoneDialogData] = useState<{
    deferred: Deferred<PhoneRegistration>
    model: string
  } | null>(null)

  const handlePendingDeviceClick = async (serial: string) => {
    const deferred = new Deferred<void>()
    setAddDeviceDialogData({
      deferred,
      serial,
    })
    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setAddDeviceDialogData(null)
    }
  }

  const handlePendingPhoneClick = async () => {
    const deferred = new Deferred<PhoneRegistration>()

    setAddPhoneDialogData({ deferred, model: pendingPhone?.model ?? '' })
    try {
      const phoneRegistration = await deferred.promise
      update('pendingPhone', phoneRegistration)
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setAddPhoneDialogData(null)
    }
  }

  const handleCleanPendingDevices = () => {
    remove('pendingDevices')
  }

  const handleCleanPendingPhone = () => {
    remove('pendingPhone')
  }

  if (pendingState.loading) {
    return <Centered>Chargement...</Centered>
  }

  if (pendingState.error) {
    return <Centered>Erreur</Centered>
  }

  const pendingPhone = pendingState.data.pendingPhone
  const pendingDevices = pendingState.data.pendingDevices ?? {}
  const recentDevices = pendingState.data.recentDevices ?? {}

  return (
    <>
      {addDeviceDialogData && <AddDeviceDialog {...addDeviceDialogData} />}

      {addPhoneDialogData && <AddPhoneDialog {...addPhoneDialogData} />}

      <div className="flex flex-1 flex-col gap-4 overflow-y-auto p-3">
        {pendingPhone ? (
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-start gap-4">
              <Title>ARI Phone en attente de création</Title>
              <Button onClick={handleCleanPendingPhone}>Réinitialiser</Button>
            </div>

            <a
              className="flex flex-row items-baseline gap-1"
              onClick={() => handlePendingPhoneClick()}
            >
              <Text>{pendingPhone.model}</Text>
              <Hint>
                Demande de création le&nbsp;
                {DateTime.fromMillis(pendingPhone.firstPing)
                  .setLocale('fr')
                  .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              </Hint>
            </a>
            {pendingPhone.facilityId && <Text>Création du compte...</Text>}
            {pendingPhone.error && (
              <Text variant="alert">${pendingPhone.error}</Text>
            )}
          </div>
        ) : (
          <Title>Aucun ARI Phone en attente de création</Title>
        )}

        {!isObjectEmpty(pendingDevices) ? (
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-start gap-4">
              <Title>ARI en attente de création</Title>
              <Button onClick={handleCleanPendingDevices}>Réinitialiser</Button>
            </div>
            <div className="flex flex-col gap-2">
              {Object.keys(pendingDevices).map((pendingSerial) => {
                const { firstPing, lastPing, colorId } =
                  pendingDevices[pendingSerial]
                const [rgbLeft, rgbRight] = getRgbsFromColorId(colorId)
                return (
                  <a
                    key={pendingSerial}
                    className="flex flex-row items-baseline gap-1"
                    onClick={() => handlePendingDeviceClick(pendingSerial)}
                  >
                    <div className="flex gap-2">
                      <LED rgb={rgbLeft} blink />
                      <LED rgb={rgbRight} />
                    </div>
                    <div className="flex flex-row items-baseline">
                      <Text>{pendingSerial}</Text>
                      <Hint>
                        Premier ping le&nbsp;
                        {DateTime.fromMillis(firstPing)
                          .setLocale('fr')
                          .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
                      </Hint>
                      {Date.now() - lastPing < MAX_LAST_PING_DELAY ? (
                        <Hint>
                          , dernier ping il y a&nbsp;
                          {Math.round(ms2sec(Date.now() - lastPing))}s
                        </Hint>
                      ) : (
                        <span className="ml-4 rounded-full bg-teal-300 px-3 text-sm">
                          Hors ligne
                        </span>
                      )}
                    </div>
                  </a>
                )
              })}
            </div>
          </div>
        ) : (
          <Title>Aucun ARI en attente de création</Title>
        )}
        <div className="flex flex-col gap-3">
          <Text>Derniers ARI créés :</Text>
          <div className="flex flex-col gap-2">
            {Object.entries(recentDevices).map(([serial, device]) => {
              return (
                <div key={serial} className="flex flex-row items-center gap-4">
                  <NavLink to={`/explorer/${device.facilityId}/${serial}`}>
                    <div className="flex items-baseline gap-2">
                      <Text>{serial}</Text>
                      <Hint>#{device.id}</Hint>
                    </div>
                  </NavLink>
                  <a
                    href={`${siteUrl('listen')}/${serial}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="rounded-full bg-current px-2 py-1 no-underline"
                  >
                    <span className="text-black">&rarr;</span>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
